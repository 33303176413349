
import OfferViewModal from "@/modules/offers/components/offer-view-modal.vue";
import { mapActions, mapGetters } from "vuex";
import i18n from "@/vueI18n";
export default {
  name: "app-offers-list-table",
  data() {
    return {
      search: "",
      searchInputAnimation: false,
      selectedRow: null,
      modalVisible: false,
      dialogVisible: false,
      dialog: false,
      image: "",
      details: "",
      // loading: false,
      pagination: {
        page: 1,
        rowsPerPage: this.getItemsPerPage(),
        searchModel: "",
        // loading:false,
        jobchanges: [],
        arrayOfObj: [],
        idOfUpdatedJob: "",
      },
      page: 1,
      rowsOld: [],
      loadingTable: false
    };
  },
  computed: {
    language() {
      return i18n.locale;
    },
    isScreenXs() {
      // console.log('screennnnn',this.$q.screen.lt)
      if (
        this.$q.screen.lt.lg &&
        this.$q.screen.lt.md &&
        this.$q.screen.lt.sm &&
        this.$q.screen.lt.xl
      ) {
        return true;
      }
      return false;
    },
    isRTL() {
      return i18n.locale == "ar";
    },
    ...mapGetters({
      rows: "offers/list/rows",
      rowsFromRoute: 'offers/list/rowsFromRoute',
      recordBeforeFirst: "offers/list/recordBeforeFirst",
      recordAfterLast: "offers/list/recordAfterLast",
      loading: "offers/list/loading",
      is_screen_xs: "layout/is_screen_xs",
    }),
    rowsPerPageOptions() {
      return [2];
    },
    rowsToBeDisplayed() {
      if (this.rows.length > 0) return this.rows;
      return this.rowsOld;
    },
  },
  watch: {
    $route(to, from){
      if(to.params.id && to.params.id != from.params.id){
        const record = this.rows.find(el => el.id == to.params.id)
        this.openModal(record)
      }
    },
    rows(newval) {
      if (newval.length > 0) {
        this.setLoadFirstRecord();
        this.setLoadMoreLast();
        this.setDisablePrevious();
        this.setDisableNext();
      }
    },

    rowsFromRoute(change){
     const page  = this.$route.query.page
     const limit = this.getItemsPerPage()
     const rowsFromRouteToBeDisplayed = change.slice(page * limit - limit , page * limit + 1)
     this.setRows(rowsFromRouteToBeDisplayed)
   },
  },
  components: {
    [OfferViewModal.name]: OfferViewModal,
  },
  methods: {
    isNumeric(value) {
      return /^\d+$/.test(value);
    },
    openModal(record) {
      this.$router.push({
        params:{
          id: record.id
        },
        query:{
          page: this.$route.query.page
        }
      })
      // console.log('record',record)
      // this.setHeaderBg("bg-shadow");
      this.modalVisible = true;
      this.dialogVisible = true;
      this.selectedRow = record;
      this.selectedId = record.id;
    },
    onModalClose() {
      // debugger
      let params = Object.assign({}, this.$route.params);
      this.$router.push({ 
        params: {
          lang: params['lang'],
          id: null
        },
        query: {
          page: this.$route.query.page
        }
      });
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 500);
    },

    scrollHandler(scroll) {
      // if (scroll.position == 0 && !this.modalVisible) {
      //   this.setHeaderBg("bg-transparent");
      // }
      // if (scroll.position > 0 && !this.modalVisible) {
      //   this.setHeaderBg("bg-shadow");
      //   this.searchInputAnimation = false;
      //   this.$emit("changeOfferHeader", false);
      // }
      if (scroll.position == 0) {
        this.setHeaderBg("bg-transparent");
      }
      if (scroll.position > 0) {
        this.setHeaderBg("bg-shadow");
        // this.searchInputAnimation = false;
        // this.$emit("changeOfferHeader", false);
      }
    },
    getItemsPerPage() {
      const { screen } = this.$q;
      if (screen.lt.sm) {
        return 4;
      }
      if (screen.lt.md) {
        return 6;
      }
      if (screen.lt.lg) {
        return 9;
      }
      if (screen.lt.xl) {
        return 9;
      }
      return 9;
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    ...mapActions({
      setHeaderBg: "layout/setHeaderBg",
      setNavbar: "layout/setNavbar",
      setFooter: "layout/setFooter",

      doFetch: "offers/list/doFetch",
      doFetchFromRoute: "offers/list/doFetchFromRoute",
      setRows: "offers/list/setRows",
      resetCursor: "offers/list/resetCursor",
      getPreviousRecordsBatch: "offers/list/getPreviousRecordsBatch",
      setLoadFirstRecord: "offers/list/setLoadFirstRecord",
      setLoadMoreLast: "offers/list/setLoadMoreLast",
      setDisablePrevious: "offers/list/setDisablePrevious",
      setDisableNext: "offers/list/setDisableNext",
    }),
    // scrollHandler(scroll){
    //   if(scroll.position == 0 )  {
    //     this.setHeaderBg('bg-transparent')
    //   }
    //   else{
    //     // this.setHeaderBg('bg-shadow animate__animated animate__fadeInDown')
    //     this.setHeaderBg('bg-shadow')
    //   }
    // },
    openDialog(image, details) {
      this.dialog = true;
      this.image = image;
      this.details = details;
    },
    closeDialog() {
      this.dialog = !this.dialog;
    },

    descriptionPresenter(description) {
      if (description) {
        if (this.isScreenXs) {
          return description.slice(0, 60);
        } else {
          return description.slice(0, 200);
        }
      }
    },
    async loadMore() {
      await this.doFetch({ limit: this.getItemsPerPage() });
      this.page = this.page + 1;
      this.$router.push({
        query: {
          page: this.page,
        },
      });
      this.rowsOld = this.rows;
    },
    async goToPreviousPage() {
      await this.getPreviousRecordsBatch({ limit: this.getItemsPerPage() });
      this.page = this.page - 1;
      this.$router.push({
        query: {
          page: this.page,
        },
      });
      this.rowsOld = this.rows;
    },
    async navigateBackwardForward() {
      if ( this.$route.name == "offers" ) {
        if (
          this.$route.query.page &&
          this.$route.query.page > 0 &&
          this.isNumeric(this.$route.query.page)
        ) {
          this.page = parseInt(this.$route.query.page)
          // debugger
          await this.doFetchFromRoute({
            page: parseInt(this.$route.query.page),
            limit: this.getItemsPerPage(),
          });
        } else {
          this.page = 1
          await this.doFetch({ page: 1, limit: this.getItemsPerPage() });
        }
      } 
      
    },
  },
  mounted() {
    this.setHeaderBg("bg-transparent");
    this.setNavbar("services");
    this.setFooter(false);
    var that = this;
    window.onpopstate = async function () {
      that.navigateBackwardForward();
    };
  },
  async created() {
    this.loadingTable = true
    this.page =
      this.$route.query.page && parseInt(this.$route.query.page) > 0
        ? parseInt(this.$route.query.page)
        : 1;

      this.setRows([]);
      this.resetCursor();
    // await this.doFetch({ limit: this.getItemsPerPage() });
    // this.rowsOld = this.rows;

    if (
      this.$route.query.page &&
      parseInt(this.$route.query.page) > 0 &&
      this.isNumeric(this.$route.query.page)
    ) {
      await this.doFetchFromRoute({
        page: parseInt(this.$route.query.page),
        limit: this.getItemsPerPage(),
      });
    } else {
      this.$router.push({
        query: {
          page: 1,
        },
      });
      await this.doFetch({
        limit: this.getItemsPerPage(),
      });
    }
    this.rowsOld = this.rows;


    if(this.$route.params.id){
      const record = this.rows.find(el => el.id == this.$route.params.id)
      this.openModal(record)
    }
    this.loadingTable = false
  },
};
