
// import {i18n} from '@/i18n'
import i18n from '@/vueI18n'
import moment from 'moment'
import { AnalyticsManager } from '@/shared/firebase/firebase-analytics';


// import { locale } from 'moment'
export default {
    name:'app-offer-view-modal',
    props:['record','visible'],
    computed:{
        isRTL(){
            return i18n.locale == "ar"
        },
        dialogVisible:{
            get(){
                return this.visible
            },
            set(){
                 this.$emit('close')
            }
        }
    },
    methods:{
        datePresenter(date) {
            const language = localStorage.getItem('language')
            return moment(new Date(date.seconds * 1000)).locale(language).format('DD-MM-yyyy hh:mm a')
            // let dateUpdated = new Date(date.seconds * 1000).toString().slice(0, 15);
            // return dateUpdated;
        },
        i18n(key, args) {
            return this.$t(key, args);
        },
        close(){
            this.$emit('closeDialog')
        }
    },
    
    mounted(){
        AnalyticsManager.logOfferSelection(this.record.id, this.record.title.en)
    },
    created() {
        // debugger
        
    }
}
