
import OfferListTable from '@/modules/offers/components/offer-list-table.vue';
import { mapActions, mapGetters } from 'vuex';
// import { i18n } from '@/i18n'
import i18n from '@/vueI18n';
import ImageBackground from '@/modules/home/components/image-background.vue'
import { AnalyticsManager } from '@/shared/firebase/firebase-analytics';

export default {
    data(){
        return{
            offers:[
                {
                    id:1,
                    name:'Ahmed Yasser',
                    date:'March 25, 2020',
                    url:'images/offers-img.svg',
                    details:`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis id sit mollis 
                                libero eleifend neque aenean. Bibendum ultrices natoque lorem volutpat nunc, neque. Sed sapien.`
                },
                {
                    id:2,
                    name:'Ahmed Yasser',
                    date:'March 25, 2020',
                    url:'images/offers-img.svg',
                    details:`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis id sit mollis 
                                libero eleifend neque aenean. Bibendum ultrices natoque lorem volutpat nunc, neque. Sed sapien.`
                },
                {
                    id:3,
                    name:'Ahmed Yasser',
                    date:'March 25, 2020',
                    url:'images/offers-img.svg',
                    details:`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis id sit mollis 
                                libero eleifend neque aenean. Bibendum ultrices natoque lorem volutpat nunc, neque. Sed sapien.`
                },
                {
                    id:4,
                    name:'Ahmed Yasser',
                    date:'March 25, 2020',
                    url:'images/offers-img.svg',
                    details:`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis id sit mollis 
                                libero eleifend neque aenean. Bibendum ultrices natoque lorem volutpat nunc, neque. Sed sapien.`
                }
            ],
            dialog:false,
            image:'',
            details:'',
            displayTitle: false,
        
        }
    },
watch:{
  lang(){
    // console.log(newval);
      // debugger
      document.title =
        this.i18n('app.title') +
        ' | ' +
        this.i18n('drugDeal.services.offers') 
  }
},
computed:{
  lang(){
    // debugger    
    return i18n.locale
  },
  ...mapGetters({
    currentUser: 'auth/currentUser',
    rows:'offers/list/rows',
    mountedTable: 'offers/list/mountedTable',
  }),
   isRTL() {
      // console.log(i18n);
      // return localStorage.getItem('language') == 'ar'
      return i18n.locale == 'ar'
    },
    title(){
      // console.log(localStorage.getItem('language') == 'ar');
      return this.$t('drugDeal.services.offers')
    },
    subtitle1(){
      return this.$t('drugDeal.offers.keepUsing')
    },
    subtitle2(){
      return this.$t('drugDeal.offers.dontMiss')
    },
    subtitle3(){
      return this.$t('drugDeal.offers.latestOffers')
    },
    // subtitle2(){
    //   return this.$t('drugDeal.offers.dontMiss')
    // },
    imageBg(){
      // console.log(localStorage.getItem('language') == 'ar');
      return "https://firebasestorage.googleapis.com/v0/b/drug-deal-6e05f.appspot.com/o/public%2Foffers-bg.svg?alt=media&token=2e9f13bc-1f8e-4cfc-af68-2dbe71d62e43"
    },
    // btnDesc(){
    //   // console.log(localStorage.getItem('language') == 'ar');
    //   return this.$t('drugDeal.prescriptions.addNewPrescription')
    // },
  
},
components:{
        [OfferListTable.name]: OfferListTable,
        [ImageBackground.name]: ImageBackground

    },
methods:{
    i18n(key, args) {
      return this.$t(key, args);
    },
    ...mapActions({
        setHeaderBg:'layout/setHeaderBg',
        setNavbar:'layout/setNavbar',
        setFooter:'layout/setFooter',
        // doFetch:'offers/list/doFetch',
        setMountedTable: 'offers/list/setMountedTable'

    }),
    scrollHandler(scroll){
      if(scroll.position == 0 )  {
        this.setHeaderBg('bg-transparent')
      }
      else{
        // this.setHeaderBg('bg-shadow animate__animated animate__fadeInDown')
        this.setHeaderBg('bg-shadow')
      }
    },
    changeOfferHeader(value){
      // console.log('changePrescriptionHeader',value)
      this.displayTitle = value
    },
    openDialog(image,details){
        this.dialog=true
      this.image = image
      this.details = details
    },
    closeDialog(){
      this.dialog = !this.dialog
      
    },
},
async created(){  
  document.title = this.i18n('app.title') +
          ' | ' +
          this.i18n('drugDeal.services.offers') 

  // if(!this.mountedTable){
  //   await this.doFetch()
  //   this.setMountedTable(true)
  // }
  AnalyticsManager.logScreen('View Offer Details', 'OfferProfile');

},
mounted(){
    // console.log(this.language)
    // debugger
    this.setHeaderBg('bg-transparent')
    this.setNavbar('services')
    this.setFooter(false)
}
}
